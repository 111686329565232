<script>
  import PNotify from './PNotify.html';

  export default {
    setup (Component) {
      Component.key = 'NonBlock';

      Component.defaults = {
        // Use NonBlock.js to create a non-blocking notice. It lets the user click elements underneath it.
        nonblock: false
      };

      Component.init = (notice) => {
        return new Component({ target: document.body,
          data: {
            '_notice': notice
          } });
      };

      // Register the module with PNotify.
      PNotify.modules.NonBlock = Component;
    },

    data () {
      return Object.assign({
        '_notice': null, // The PNotify notice.
        '_options': {} // The options for the notice.
      }, PNotify.modules.NonBlock.defaults);
    },

    methods: {
      initModule (options) {
        this.set(options);
        this.doNonBlockClass();
      },

      update () {
        this.doNonBlockClass();
      },

      doNonBlockClass () {
        if (this.get().nonblock) {
          this.get()._notice.addModuleClass('nonblock');
        } else {
          this.get()._notice.removeModuleClass('nonblock');
        }
      }
    }
  };
</script>
